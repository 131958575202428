<!--  -->
<template>
  <section class="news">
    <div class="container">
      <div class="section-title">
        <h2>有问有答</h2>
        <p>了解最新公司动态及行业资讯</p>
      </div>

      <div class="news-item">
        <div class="hd">
          <ul>
            <li :class="activeIndex == index ? 'on' : ''" v-for="(newTitle, index) in newTitles" :key="index"
              @mouseover="changeNews(index, newTitle.cid)">
              {{ newTitle.title }}
            </li>
          </ul>
        </div>
        <div class="bd">
          <!-- ul -->
          <ul>
            <li class="news-item-left">
              <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" :title="newLists.title">
                <div class="news-img">
                  <img :src="'https://www.hg-zn.com/' + newLists.image_input" :alt="newLists.title" height="225" />
                </div>
                <div class="news-txt">
                  <h4>{{ newLists.title }}</h4>
                  <span>{{ newLists.synopsis }}...</span>
                  <div class="news-icon">
                    <span>
                      <i class="fa fa-clock-o" aria-hidden="true"></i>{{ newLists.add_time }}
                    </span>
                    <span>
                      <i class="fa fa-eye" aria-hidden="true"></i>{{ newLists.visit }}
                    </span>
                    <span>
                      <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>{{ newLists.z_num }}
                    </span>
                  </div>
                </div>
              </a>
            </li>
            <li class="news-item-right">
              <ul>
                <li v-for="newlist in newLists.lists" :key="newlist.id">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" :title="newlist.title">
                    <div class="news-date">
                      <strong>{{ newlist.add_time | timeFormat }}</strong>{{ newlist.add_time | timeFormat2 }}
                    </div>
                    <div class="news-text">
                      <h5>{{ newlist.title }}</h5>
                      <span>{{ newlist.synopsis }}···</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="more">
          <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title>
            查看更多&nbsp;&nbsp;
            <i class="fa fa-angle-double-right"></i>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { HomeNewsApi } from "@/api/home.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      activeIndex: 0,
      newTitles: [
        {
          cid: 1,
          title: "好谷动态",
        },
        {
          cid: 2,
          title: "行业动态",
        },
        {
          cid: 3,
          title: "学习分享",
        },
        {
          cid: 4,
          title: "面经",
        },
        {
          cid: 5,
          title: "面试题",
        },
      ],
      cid: 1,
      newLists: {},
    };
  },
  filters: {
    timeFormat(value) {
      // console.log(value);
      return value.slice(8, 10);
    },
    timeFormat2(value) {
      // console.log(value);
      return value.slice(0, 7);
    },
  },
  //方法集合
  methods: {
    changeNews(index, cid) {
      this.activeIndex = index;
      this.cid = cid;
      this.getHomeNewsDatas();
    },
    getHomeNewsDatas() {
      HomeNewsApi({
        cid: this.cid,
      }).then((res) => {
        // console.log(res.data.data);
        this.newLists = res.data.data;
      });
    },
  },

  mounted() {
    this.getHomeNewsDatas();
  },
};
</script>
<style></style>