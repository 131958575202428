<!--  -->
<template>
  <section class="product">
    <div class="container">
      <div class="product-item">
        <ul class="clearfix">
          <li data-index="1" @mouseenter="activeFn" :class="{ 'product-active': activeIndex == 1 }">
            <div class="product-item-normal">
              <div class="product-item-icon">
                <i class="fa fa-laptop fa-2x fa-fw" aria-hidden="true"></i>
              </div>
              <div class="product-item-title">
                <h3>核心编程技术</h3>
                <p>JAVA面向对象、数据库MySQL</p>
              </div>
              <div class="product-item-desc">
                <p>【 PC端网页开发 】</p>
                <p>【 HTML5+CSS3 】</p>
                <p>【 移动端网页开发 】</p>
              </div>
              <div class="product-item-price">
                <p>
                  <em>就业薪资</em>
                  <span>3K-5K</span>
                </p>
              </div>
            </div>
            <div class="product-item-active">
              <a href class="product-item-link">
                <div class="product-item-icon">
                  <i class="fa fa-laptop fa-3x fa-fw" aria-hidden="true"></i>
                </div>
                <div class="product-item-title">
                  <h3>核心编程技术</h3>
                  <p>JAVA面向对象、数据库MySQL</p>
                </div>
                <div class="product-item-desc" style="height: 280px; width: 95%; overflow-y: auto">
                  <p>
                    •课程架构分析以及Java入门介绍，了解Java基本语法以及Java语言的特点，能够简单的语法编程。
                  </p>
                  <p>
                    •学习并了解Java面向对象三大特点：继承、封装和多态，以及学习Java复杂语句：集合，多线程，异常，网络通信等。
                  </p>
                  <p>• Mysql安装和配置，以及语句的编写。</p>
                </div>
                <div class="product-item-btn">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
                    立即试听
                  </a>
                </div>
              </a>
            </div>
          </li>
          <li data-index="2" @mouseenter="activeFn" :class="{ 'product-active': activeIndex == 2 }">
            <div class="product-item-normal">
              <div class="product-item-icon">
                <i class="fa fa-mobile fa-2x fa-fw" aria-hidden="true"></i>
              </div>
              <div class="product-item-title">
                <h3>JAVA WEB开发</h3>
                <p>大型客户后台管理系统实战</p>
              </div>
              <div class="product-item-desc">
                <p>【 HTML5、CSS3 】</p>
                <p>【 JavaScript/jQuery 】</p>
                <p>【 JSP/SERVLET 】</p>
              </div>
              <div class="product-item-price">
                <p>
                  <em>就业薪资</em>
                  <span>5K-8K</span>
                </p>
              </div>
            </div>
            <div class="product-item-active">
              <a href class="product-item-link">
                <div class="product-item-icon">
                  <i class="fa fa-mobile fa-3x fa-fw" aria-hidden="true"></i>
                </div>
                <div class="product-item-title">
                  <h3>JAVA WEB企业级开发</h3>
                  <p>大型客户后台管理系统实战</p>
                </div>
                <div class="product-item-desc" style="height: 280px; width: 95%; overflow-y: auto">
                  <p>
                    •学习HTML的标签、属性等知识，学习DIV+CSS的基础知识，掌握网页布局技巧，能够用DIV+CSS完成带交互的企业网站的布局。
                  </p>
                  <p>
                    •通过JavaScript基础课程和高级课程的学习，掌握时下流行的各种网页交互的原理和方法。
                  </p>
                  <p>
                    •通过对服务器tomcat的讲解，JSP页面内容，servlet，el表达式，jstl标签，过滤，监听，ajax的相关内容学习。
                  </p>
                </div>
                <div class="product-item-btn">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
                    立即试听
                  </a>
                </div>
              </a>
            </div>
          </li>
          <li data-index="3" @mouseenter="activeFn" :class="{ 'product-active': activeIndex == 3 }">
            <div class="product-item-normal">
              <div class="product-item-icon">
                <i class="fa fa-bar-chart fa-2x fa-fw" aria-hidden="true"></i>
              </div>
              <div class="product-item-title">
                <h3>⼤型企业级框架</h3>
                <p>大型企业权限管理系统</p>
              </div>
              <div class="product-item-desc">
                <p>【 数据库操作与管理 】</p>
                <p>【 git/maven 】</p>
                <p>【 Spring+springmvc 】</p>
              </div>
              <div class="product-item-price">
                <p>
                  <em>就业薪资</em>
                  <span>8K-11K</span>
                </p>
              </div>
            </div>
            <div class="product-item-active">
              <a href class="product-item-link">
                <div class="product-item-icon">
                  <i class="fa fa-bar-chart fa-3x fa-fw" aria-hidden="true"></i>
                </div>
                <div class="product-item-title">
                  <h3>⼤型企业级框架</h3>
                  <p>大型企业权限管理系统</p>
                </div>
                <div class="product-item-desc" style="height: 280px; width: 95%; overflow-y: auto">
                  <p>
                    •orcale数据库的安装，操作，内部结构，存储过程，包，索引，分区，优化。
                  </p>
                  <p>•git学习上传，下拉。Maven结构，创建项目，介绍。</p>
                  <p>
                    •spring中IOC，DI，AOP等讲解，spring中JDBC的支持。对事务的支持，springmvc整合。
                  </p>
                </div>
                <div class="product-item-btn">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
                    立即试听
                  </a>
                </div>
              </a>
            </div>
          </li>
          <li data-index="4" @mouseenter="activeFn" :class="{ 'product-active': activeIndex == 4 }">
            <div class="product-item-normal">
              <div class="product-item-icon">
                <i class="fa fa-cog fa-2x fa-fw" aria-hidden="true"></i>
              </div>
              <div class="product-item-title">
                <h3>微服务分布式</h3>
                <p>高并发微服务分布式解决方案</p>
              </div>
              <div class="product-item-desc">
                <p>【 Linux操作系统 】</p>
                <p>【 RabbitMQ/ActiveMQ 】</p>
                <p>【 Spring Boot 】</p>
                <p>【 SpringCloud 】</p>
                <p>【 大数据平台搭建 】</p>
              </div>
              <div class="product-item-price">
                <p>
                  <em>就业薪资</em>
                  <span>11K-18K</span>
                </p>
              </div>
            </div>
            <div class="product-item-active">
              <a href class="product-item-link">
                <div class="product-item-icon">
                  <i class="fa fa-cog fa-3x fa-fw" aria-hidden="true"></i>
                </div>
                <div class="product-item-title">
                  <h3>微服务分布式</h3>
                  <p>高并发微服务分布式解决方案</p>
                </div>
                <div class="product-item-desc" style="height: 280px; width: 95%; overflow-y: auto">
                  <p>•Linxu系统结构以及基本命令，部署开发环境，搭建集群。</p>
                  <p>
                    •nginx的安装、Nginx配置虚拟主机、Nginx实现反向代理、Nginx实
                    现负载均衡。
                  </p>
                  <p>
                    •Spring家族微服务、分布式系统Dubbo的应用、分布式系统讲解，Dubbo入门、负载均衡+zookeeper、dubbo+spring实战讲解
                  </p>
                  <p>
                    •集群搭建，hadoop生态讲解，hdfs讲解，java代码在hdfs上完成单词统计案例
                  </p>
                  <p>
                    •solr
                    服务器搭建、建立索引，维护索引、数据检索(全文搜索，高亮显示，精确搜索等)、配置分词器。
                  </p>
                </div>
                <div class="product-item-btn">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
                    立即试听
                  </a>
                </div>
              </a>
            </div>
          </li>
          <li data-index="5" @mouseenter="activeFn" :class="{ 'product-active': activeIndex == 5 }">
            <div class="product-item-normal">
              <div class="product-item-icon">
                <i class="fa fa-registered fa-2x fa-fw" aria-hidden="true"></i>
              </div>
              <div class="product-item-title">
                <h3>实战项目演练</h3>
                <p>JAVA互联网百强实战项目演练</p>
              </div>
              <div class="product-item-desc">
                <p>【 实战项目模拟 】</p>
                <p>【 项目实战研发 】</p>
              </div>
              <div class="product-item-price">
                <p>
                  <em>就业薪资</em>
                  <span>15K-20K</span>
                </p>
              </div>
            </div>
            <div class="product-item-active">
              <a href="#" class="product-item-link">
                <div class="product-item-icon">
                  <i class="fa fa-registered fa-3x fa-fw" aria-hidden="true"></i>
                </div>
                <div class="product-item-title">
                  <h3>实战项目演练</h3>
                  <p>JAVA互联网百强实战项目演练</p>
                </div>
                <div class="product-item-desc" style="height: 280px; width: 95%; overflow-y: auto">
                  <p>
                    •某某政府微服务治理系统、大型集团ERP管理系统、大型网高并发网约车管理系统、国际律师网管理系统、超大型编程教育系统
                  </p>
                  <p>•⼿把手带领完成分布式高并发微服务架构、双十一好谷商城。</p>
                  <p>•分级制度因材施教，指导完成同等规模项目</p>
                </div>
                <div class="product-item-btn">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
                    立即试听
                  </a>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      activeIndex: 1,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    activeFn(e) {
      this.activeIndex = e.target.getAttribute("data-index");
    },
  },
  beforeCreate() { }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  beforeMount() { }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
</style>