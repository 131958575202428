import service from "../utils/request"


export const HomeNewsApi = (params) => {
    return service({
        url: "/api/article/list",
        method: "get",
        params: params
    })
}

export const HomeCaseApi = (params) => {
    return service({
        url: "/api/case/list",
        method: "get",
        params: params
    })
}

export const ClassRoomApi = (params) => {
    return service({
        url: "/api/classroom/list",
        method: "get",
        params: params
    })
}

// /classroom/add

export const ClassRoomAddApi = (data) => {
    return service({
        url: "/api/classroom/add",
        method: "post",
        data: data
    })
}

// 获取短信验证码
export const PhoneCode = (data) => {
    return service({
        url: "/admin/api/getcode",
        method: "post",
        data: data
    })
}

// 获取短信验证码
export const SendMsg = (data) => {
    return service({
        url: "/admin/api/sendmsg",
        method: "post",
        data: data
    })
}

// 注册手机用户，并跳转到选座位
export const PhoneLoginCode = (data) => {
    return service({
        url: "/admin/api/phonelogin",
        method: "post",
        data: data
    })
}