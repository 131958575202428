import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomePage/Home.vue'

Vue.use(VueRouter)
//解决刷新报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
  path: "/",
  redirect: "/home"
},
{
  path: '/home',
  name: 'Home',
  component: Home
},
{
  path: '/mobile',
  name: 'Mobile',
  component: () => import('@/views/MobilePage/MobilePage.vue')
},
{
  path: '/classroom/:cid',
  name: 'ClassRoom',
  component: () => import('@/views/ClassRoom/ClassRoom.vue')
}
]

const router = new VueRouter({
  routes
})

export default router
