var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"product"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"product-item"},[_c('ul',{staticClass:"clearfix"},[_c('li',{class:{ 'product-active': _vm.activeIndex == 1 },attrs:{"data-index":"1"},on:{"mouseenter":_vm.activeFn}},[_vm._m(0),_vm._m(1)]),_c('li',{class:{ 'product-active': _vm.activeIndex == 2 },attrs:{"data-index":"2"},on:{"mouseenter":_vm.activeFn}},[_vm._m(2),_vm._m(3)]),_c('li',{class:{ 'product-active': _vm.activeIndex == 3 },attrs:{"data-index":"3"},on:{"mouseenter":_vm.activeFn}},[_vm._m(4),_vm._m(5)]),_c('li',{class:{ 'product-active': _vm.activeIndex == 4 },attrs:{"data-index":"4"},on:{"mouseenter":_vm.activeFn}},[_vm._m(6),_vm._m(7)]),_c('li',{class:{ 'product-active': _vm.activeIndex == 5 },attrs:{"data-index":"5"},on:{"mouseenter":_vm.activeFn}},[_vm._m(8),_vm._m(9)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-normal"},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-laptop fa-2x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("核心编程技术")]),_c('p',[_vm._v("JAVA面向对象、数据库MySQL")])]),_c('div',{staticClass:"product-item-desc"},[_c('p',[_vm._v("【 PC端网页开发 】")]),_c('p',[_vm._v("【 HTML5+CSS3 】")]),_c('p',[_vm._v("【 移动端网页开发 】")])]),_c('div',{staticClass:"product-item-price"},[_c('p',[_c('em',[_vm._v("就业薪资")]),_c('span',[_vm._v("3K-5K")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-active"},[_c('a',{staticClass:"product-item-link",attrs:{"href":""}},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-laptop fa-3x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("核心编程技术")]),_c('p',[_vm._v("JAVA面向对象、数据库MySQL")])]),_c('div',{staticClass:"product-item-desc",staticStyle:{"height":"280px","width":"95%","overflow-y":"auto"}},[_c('p',[_vm._v(" •课程架构分析以及Java入门介绍，了解Java基本语法以及Java语言的特点，能够简单的语法编程。 ")]),_c('p',[_vm._v(" •学习并了解Java面向对象三大特点：继承、封装和多态，以及学习Java复杂语句：集合，多线程，异常，网络通信等。 ")]),_c('p',[_vm._v("• Mysql安装和配置，以及语句的编写。")])]),_c('div',{staticClass:"product-item-btn"},[_c('a',{attrs:{"href":"https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1"}},[_vm._v(" 立即试听 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-normal"},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-mobile fa-2x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("JAVA WEB开发")]),_c('p',[_vm._v("大型客户后台管理系统实战")])]),_c('div',{staticClass:"product-item-desc"},[_c('p',[_vm._v("【 HTML5、CSS3 】")]),_c('p',[_vm._v("【 JavaScript/jQuery 】")]),_c('p',[_vm._v("【 JSP/SERVLET 】")])]),_c('div',{staticClass:"product-item-price"},[_c('p',[_c('em',[_vm._v("就业薪资")]),_c('span',[_vm._v("5K-8K")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-active"},[_c('a',{staticClass:"product-item-link",attrs:{"href":""}},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-mobile fa-3x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("JAVA WEB企业级开发")]),_c('p',[_vm._v("大型客户后台管理系统实战")])]),_c('div',{staticClass:"product-item-desc",staticStyle:{"height":"280px","width":"95%","overflow-y":"auto"}},[_c('p',[_vm._v(" •学习HTML的标签、属性等知识，学习DIV+CSS的基础知识，掌握网页布局技巧，能够用DIV+CSS完成带交互的企业网站的布局。 ")]),_c('p',[_vm._v(" •通过JavaScript基础课程和高级课程的学习，掌握时下流行的各种网页交互的原理和方法。 ")]),_c('p',[_vm._v(" •通过对服务器tomcat的讲解，JSP页面内容，servlet，el表达式，jstl标签，过滤，监听，ajax的相关内容学习。 ")])]),_c('div',{staticClass:"product-item-btn"},[_c('a',{attrs:{"href":"https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1"}},[_vm._v(" 立即试听 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-normal"},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-bar-chart fa-2x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("⼤型企业级框架")]),_c('p',[_vm._v("大型企业权限管理系统")])]),_c('div',{staticClass:"product-item-desc"},[_c('p',[_vm._v("【 数据库操作与管理 】")]),_c('p',[_vm._v("【 git/maven 】")]),_c('p',[_vm._v("【 Spring+springmvc 】")])]),_c('div',{staticClass:"product-item-price"},[_c('p',[_c('em',[_vm._v("就业薪资")]),_c('span',[_vm._v("8K-11K")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-active"},[_c('a',{staticClass:"product-item-link",attrs:{"href":""}},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-bar-chart fa-3x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("⼤型企业级框架")]),_c('p',[_vm._v("大型企业权限管理系统")])]),_c('div',{staticClass:"product-item-desc",staticStyle:{"height":"280px","width":"95%","overflow-y":"auto"}},[_c('p',[_vm._v(" •orcale数据库的安装，操作，内部结构，存储过程，包，索引，分区，优化。 ")]),_c('p',[_vm._v("•git学习上传，下拉。Maven结构，创建项目，介绍。")]),_c('p',[_vm._v(" •spring中IOC，DI，AOP等讲解，spring中JDBC的支持。对事务的支持，springmvc整合。 ")])]),_c('div',{staticClass:"product-item-btn"},[_c('a',{attrs:{"href":"https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1"}},[_vm._v(" 立即试听 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-normal"},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-cog fa-2x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("微服务分布式")]),_c('p',[_vm._v("高并发微服务分布式解决方案")])]),_c('div',{staticClass:"product-item-desc"},[_c('p',[_vm._v("【 Linux操作系统 】")]),_c('p',[_vm._v("【 RabbitMQ/ActiveMQ 】")]),_c('p',[_vm._v("【 Spring Boot 】")]),_c('p',[_vm._v("【 SpringCloud 】")]),_c('p',[_vm._v("【 大数据平台搭建 】")])]),_c('div',{staticClass:"product-item-price"},[_c('p',[_c('em',[_vm._v("就业薪资")]),_c('span',[_vm._v("11K-18K")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-active"},[_c('a',{staticClass:"product-item-link",attrs:{"href":""}},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-cog fa-3x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("微服务分布式")]),_c('p',[_vm._v("高并发微服务分布式解决方案")])]),_c('div',{staticClass:"product-item-desc",staticStyle:{"height":"280px","width":"95%","overflow-y":"auto"}},[_c('p',[_vm._v("•Linxu系统结构以及基本命令，部署开发环境，搭建集群。")]),_c('p',[_vm._v(" •nginx的安装、Nginx配置虚拟主机、Nginx实现反向代理、Nginx实 现负载均衡。 ")]),_c('p',[_vm._v(" •Spring家族微服务、分布式系统Dubbo的应用、分布式系统讲解，Dubbo入门、负载均衡+zookeeper、dubbo+spring实战讲解 ")]),_c('p',[_vm._v(" •集群搭建，hadoop生态讲解，hdfs讲解，java代码在hdfs上完成单词统计案例 ")]),_c('p',[_vm._v(" •solr 服务器搭建、建立索引，维护索引、数据检索(全文搜索，高亮显示，精确搜索等)、配置分词器。 ")])]),_c('div',{staticClass:"product-item-btn"},[_c('a',{attrs:{"href":"https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1"}},[_vm._v(" 立即试听 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-normal"},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-registered fa-2x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("实战项目演练")]),_c('p',[_vm._v("JAVA互联网百强实战项目演练")])]),_c('div',{staticClass:"product-item-desc"},[_c('p',[_vm._v("【 实战项目模拟 】")]),_c('p',[_vm._v("【 项目实战研发 】")])]),_c('div',{staticClass:"product-item-price"},[_c('p',[_c('em',[_vm._v("就业薪资")]),_c('span',[_vm._v("15K-20K")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-active"},[_c('a',{staticClass:"product-item-link",attrs:{"href":"#"}},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-registered fa-3x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("实战项目演练")]),_c('p',[_vm._v("JAVA互联网百强实战项目演练")])]),_c('div',{staticClass:"product-item-desc",staticStyle:{"height":"280px","width":"95%","overflow-y":"auto"}},[_c('p',[_vm._v(" •某某政府微服务治理系统、大型集团ERP管理系统、大型网高并发网约车管理系统、国际律师网管理系统、超大型编程教育系统 ")]),_c('p',[_vm._v("•⼿把手带领完成分布式高并发微服务架构、双十一好谷商城。")]),_c('p',[_vm._v("•分级制度因材施教，指导完成同等规模项目")])]),_c('div',{staticClass:"product-item-btn"},[_c('a',{attrs:{"href":"https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1"}},[_vm._v(" 立即试听 ")])])])])
}]

export { render, staticRenderFns }