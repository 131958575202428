<!--  -->
<template>
  <section class="product">
    <div class="container">
      <div class="product-item">
        <ul class="clearfix">
          <li data-index="1" @mouseenter="activeFn" :class="{ 'product-active': activeIndex == 1 }">
            <div class="product-item-normal">
              <div class="product-item-icon">
                <i class="fa fa-laptop fa-2x fa-fw" aria-hidden="true"></i>
              </div>
              <div class="product-item-title">
                <h3>静态网页开发</h3>
                <p>前端基础入门</p>
              </div>
              <div class="product-item-desc">
                <p>【 PC端网页开发 】</p>
                <p>【 HTML5+CSS3 】</p>
                <p>【 移动端网页开发 】</p>
              </div>
              <div class="product-item-price">
                <p>
                  <em>就业薪资</em>
                  <span>3K-5K</span>
                </p>
              </div>
            </div>
            <div class="product-item-active">
              <a href class="product-item-link">
                <div class="product-item-icon">
                  <i class="fa fa-laptop fa-3x fa-fw" aria-hidden="true"></i>
                </div>
                <div class="product-item-title">
                  <h3>静态网页开发</h3>
                  <p>可掌握的核心能力</p>
                </div>
                <div class="product-item-desc" style="height: 280px; width: 95%; overflow-y: auto">
                  <p>•熟练运用HTML语法</p>
                  <p>•掌握语义化的本质</p>
                  <p>•合理使用HTML标签编写网页的结构</p>
                  <p>•通过浮动、定位、Flex控制网页布局</p>
                  <p>•通过背景、圆角、阴影、渐变等美化网页</p>
                  <p>•结合转换、过渡、动画等高级特性增强用户体验</p>
                  <p>•能够编写大型综合性网页</p>
                  <p>•基于设计稿编写网页</p>
                  <p>•借助蓝湖协同平台进行网页开发</p>
                  <p>•体验真实团队开发流程。</p>
                </div>
                <div class="product-item-btn">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
                    立即试听
                  </a>
                </div>
              </a>
            </div>
          </li>
          <li data-index="2" @mouseenter="activeFn" :class="{ 'product-active': activeIndex == 2 }">
            <div class="product-item-normal">
              <div class="product-item-icon">
                <i class="fa fa-mobile fa-2x fa-fw" aria-hidden="true"></i>
              </div>
              <div class="product-item-title">
                <h3>动态网页开发</h3>
                <p>能够开发出具有交互的网页</p>
              </div>
              <div class="product-item-desc">
                <p>【 JavaScript 】</p>
                <p>【 jQuery框架 】</p>
                <p>【 BootStrap 】</p>
              </div>
              <div class="product-item-price">
                <p>
                  <em>就业薪资</em>
                  <span>5K-8K</span>
                </p>
              </div>
            </div>
            <div class="product-item-active">
              <a href class="product-item-link">
                <div class="product-item-icon">
                  <i class="fa fa-mobile fa-3x fa-fw" aria-hidden="true"></i>
                </div>
                <div class="product-item-title">
                  <h3>动态网页开发</h3>
                  <p>可掌握的核心能力</p>
                </div>
                <div class="product-item-desc" style="height: 280px; width: 95%; overflow-y: auto">
                  <p>•掌握客户端开发技术，如视觉交互、数据处理、安全、性能</p>
                  <p>•掌握熟练操作 DOM 的能力</p>
                  <p>•熟悉 ES6+ 语法特性</p>
                  <p>•具备面向对象编程的能力</p>
                  <p>•运用 jQuery 提高开发效率，深入面向对象思想</p>
                  <p>•运用 Bootstrap 框架开发响应式网页</p>
                </div>
                <div class="product-item-btn">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
                    立即试听
                  </a>
                </div>
              </a>
            </div>
          </li>
          <li data-index="3" @mouseenter="activeFn" :class="{ 'product-active': activeIndex == 3 }">
            <div class="product-item-normal">
              <div class="product-item-icon">
                <i class="fa fa-bar-chart fa-2x fa-fw" aria-hidden="true"></i>
              </div>
              <div class="product-item-title">
                <h3>全栈开发</h3>
                <p>实现客户端与服务端的交互</p>
              </div>
              <div class="product-item-desc">
                <p>【 NodeJS 】</p>
                <p>【 AJAX 】</p>
                <p>【 MongoDB/MySql 】</p>
                <p>【 模板引擎 】</p>
              </div>
              <div class="product-item-price">
                <p>
                  <em>就业薪资</em>
                  <span>8K-11K</span>
                </p>
              </div>
            </div>
            <div class="product-item-active">
              <a href class="product-item-link">
                <div class="product-item-icon">
                  <i class="fa fa-bar-chart fa-3x fa-fw" aria-hidden="true"></i>
                </div>
                <div class="product-item-title">
                  <h3>全栈开发</h3>
                  <p>可掌握的核心能力</p>
                </div>
                <div class="product-item-desc" style="height: 280px; width: 95%; overflow-y: auto">
                  <p>•掌握网页数据交互的 Ajax 技术</p>
                  <p>•掌握 Node.js 中核心模块</p>
                  <p>•具备 Node.js 的编程思维</p>
                  <p>•掌握 npm 的基本使用</p>
                  <p>•根据发包流程将自己开发的库发布到 npm 平台</p>
                  <p>•了解后端数据库的基本使用</p>
                  <p>•能够使用常见的 SQL 语句操作数据库</p>
                  <p>•能够使用 MySQL 模块在项目中操作 MySQL 数据库</p>
                  <p>•掌握 Express 结合 MySQL 编写后端 API 接口</p>
                  <p>•培养出懂后端的前端程序员</p>
                  <p>•运用 Git 对项目进行版本控制</p>
                  <p>•掌握实际开发中那些必知必会的 Git 操作</p>
                  <p>
                    •基于 jQuery + Ajax + 模板引擎 + Git
                    等技术完成后台管理项目的开发
                  </p>
                </div>
                <div class="product-item-btn">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
                    立即试听
                  </a>
                </div>
              </a>
            </div>
          </li>
          <li data-index="4" @mouseenter="activeFn" :class="{ 'product-active': activeIndex == 4 }">
            <div class="product-item-normal">
              <div class="product-item-icon">
                <i class="fa fa-cog fa-2x fa-fw" aria-hidden="true"></i>
              </div>
              <div class="product-item-title">
                <h3>移动端APP开发</h3>
                <p>主流框架讲解</p>
              </div>
              <div class="product-item-desc">
                <p>【 VueJS 】</p>
                <p>【 ReactJS 】</p>
                <p>【 项目讲解 】</p>
              </div>
              <div class="product-item-price">
                <p>
                  <em>就业薪资</em>
                  <span>11K-18K</span>
                </p>
              </div>
            </div>
            <div class="product-item-active">
              <a href class="product-item-link">
                <div class="product-item-icon">
                  <i class="fa fa-cog fa-3x fa-fw" aria-hidden="true"></i>
                </div>
                <div class="product-item-title">
                  <h3>移动端APP开发</h3>
                  <p>可掌握的核心能力</p>
                </div>
                <div class="product-item-desc" style="height: 280px; width: 95%; overflow-y: auto">
                  <p>•了解组件化开发的思想</p>
                  <p>•了解路由的原理</p>
                  <p>•掌握 Webpack 的基本使用</p>
                  <p>•理解数据驱动视图的思想</p>
                  <p>•掌握 Vue 的使用步骤</p>
                  <p>
                    •掌握 Vue
                    条件渲染、列表渲染、过滤器、生命周期、计算属性、侦听器等常用
                    API
                  </p>
                  <p>•掌握 ElementUI 的基本使用</p>
                  <p>•掌握 Vue 中的组件通信技术</p>
                  <p>•掌握 Vue 常用技术栈，如 vue-cli、vue-router、vuex、vue</p>
                  <p>•运用 Vue 的基本语法开发电商后台管理系统</p>
                  <p>•运用 Vue 开发人资管理平台</p>
                  <p>•运用 Vue 移动端网站</p>
                  <p>•掌握 Vant 的使用</p>
                  <p>•熟悉企业开发流程</p>
                  <p>•熟悉团队开发流程</p>
                  <p>•运用节流和防抖进行性能优化</p>
                  <p>•理解 MVVM 的原理</p>
                  <p>•熟悉 defineProperty 的使用</p>
                  <p>•了解发布订阅设计模式</p>
                  <p>•了解 Proxy 的使用</p>
                  <p>•掌握 npm 平台发包步骤</p>
                  <p>•掌握 Vue 组件封装的技巧</p>
                  <p>掌握 React 的基本语法</p>
                  <p>•掌握 JSX 的常用语法</p>
                  <p>•掌握 create-react-app 的使用</p>
                  <p>•掌握路由的使用</p>
                  <p>•掌握 React 中组件化开发的能力</p>
                  <p>•了解高阶组件的基本使用</p>
                  <p>•熟悉组件通信的常用方法。</p>
                  <p>•掌握 React 的生命周期</p>
                  <p>•掌握 Redux 的数据管理流程</p>
                  <p>•掌握 Redux 中各组成部分的作用</p>
                  <p>•掌握 React-Redux 的基本使用</p>
                  <p>•了解展示组件和容器组件的区别</p>
                  <p>•了解单向数据流的概念</p>
                  <p>•掌握 React 中 dev-tools 的使用。</p>
                  <p>•掌握 Reac 技术栈进行移动端网站的能力</p>
                  <p>•了解百度地图 SDK 的基本使用</p>
                  <p>•掌握 antd 的基本使用</p>
                  <p>•了解项目中常见业务的开发思路</p>
                  <p>•了解组件化开发思想</p>
                  <p>•了解 CSS in JS 思想</p>
                </div>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
                  立即试听
                </a>
              </a>
            </div>
          </li>
          <li data-index="5" @mouseenter="activeFn" :class="{ 'product-active': activeIndex == 5 }">
            <div class="product-item-normal">
              <div class="product-item-icon">
                <i class="fa fa-registered fa-2x fa-fw" aria-hidden="true"></i>
              </div>
              <div class="product-item-title">
                <h3>多端应用开发</h3>
                <p>小程序开发和大屏可视化开发</p>
              </div>
              <div class="product-item-desc">
                <p>【 微信小程序 】</p>
                <p>【 支付宝小程序 】</p>
                <p>【 抖音小程序 】</p>
                <p>【 大屏可视化解决方案 】</p>
              </div>
              <div class="product-item-price">
                <p>
                  <em>就业薪资</em>
                  <span>15K-20K</span>
                </p>
              </div>
            </div>
            <div class="product-item-active">
              <a href="#" class="product-item-link">
                <div class="product-item-icon">
                  <i class="fa fa-registered fa-3x fa-fw" aria-hidden="true"></i>
                </div>
                <div class="product-item-title">
                  <h3>多端应用开发</h3>
                  <p>可掌握的核心能力</p>
                </div>
                <div class="product-item-desc" style="height: 280px; width: 95%; overflow-y: auto">
                  <p>•了解小程序开发的一般流程</p>
                  <p>•熟悉小程基础组件的使用</p>
                  <p>•能够基于 wxml 和 wxss 实现小程序的界面布局</p>
                  <p>•基于模板的数据绑定实现数据的动态渲染</p>
                  <p>•基于 API 实现与服务端的数据通信</p>
                  <p>•掌握小程序中高级 API 的使用。</p>
                  <p>•理解 uni-app 实现跨平台开发的原理机制</p>
                  <p>•以及 uni-app 开发小程序时开发时与原生小程序的差异</p>
                  <p>•熟悉微信支付的接入流程</p>
                  <p>•利用数据缓存实现性能优化</p>
                  <p>•了解数字大屏开发流程</p>
                  <p>•运用 Echarts 完成数据可视化项目的开发</p>
                </div>
                <div class="product-item-btn">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
                    立即试听
                  </a>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      activeIndex: 1,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    activeFn(e) {
      this.activeIndex = e.target.getAttribute("data-index");
    },
  },
  beforeCreate() { }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  beforeMount() { }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
</style>