<!--  -->
<template>
  <section class="product">
    <section class="product" style="padding-bottom: 0">
      <div class="container">
        <div class="section-title" style="padding: 0">
          <h2>
            学习资料<span style="color: #f55; font-size: 30px">免费领</span>
          </h2>
          <p>互联网热门IT开发技术，总有你喜欢的一款</p>
        </div>
      </div>
    </section>
    <div class="container">
      <ul class="lesson-wrap">
        <li v-for="lessonListData in lessonListDatas" :key="lessonListData.id">
          <div class="lesson-img">
            <img :src="lessonListData.pic" alt="" />
          </div>
          <div class="info">
            <p>
              {{ lessonListData.title }}
            </p>
          </div>
          <div class="lesson-icons">
            <span>
              <i class="fa fa-clock-o" aria-hidden="true"></i>{{ lessonListData.create_time }}
            </span>
            <span>
              <i class="fa fa-eye" aria-hidden="true"></i>{{ lessonListData.read_num }}
            </span>
            <span>
              <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>{{ lessonListData.like_num }}
            </span>
          </div>
          <div class="mask">
            <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
              <button class="try_btn">0元试听</button></a>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      activeIndex: 1,
      lessonListDatas: [
        {
          id: 1,
          pic: require("@/assets/images/lesson01.jpg"),
          title: "HTML+CSS零基础转行入门实战",
          create_time: "2022-04-01",
          read_num: 6523,
          like_num: 2359,
        },
        {
          id: 2,
          pic: require("@/assets/images/lesson02.jpg"),
          title: "javaScript零基础必备教程",
          create_time: "2022-04-03",
          read_num: 7813,
          like_num: 6542,
        },
        {
          id: 3,
          pic: require("@/assets/images/lesson03.jpg"),
          title: "javaScript动画入门实战课程",
          create_time: "2022-04-05",
          read_num: 5003,
          like_num: 3999,
        },
        {
          id: 4,
          pic: require("@/assets/images/lesson04.jpg"),
          title: "ES6教程，涵盖ES6-ES11",
          create_time: "2022-04-05",
          read_num: 1300,
          like_num: 989,
        },
        {
          id: 5,
          pic: require("@/assets/images/lesson05.jpg"),
          title: "全新 Vue.js 3.0 核心技术合集",
          create_time: "2022-04-07",
          read_num: 1970,
          like_num: 1234,
        },
        {
          id: 6,
          pic: require("@/assets/images/lesson06.jpg"),
          title: "Redis视频教程-Redis深入浅出",
          create_time: "2022-04-08",
          read_num: 1100,
          like_num: 999,
        },
        {
          id: 7,
          pic: require("@/assets/images/lesson07.jpg"),
          title: "一周轻松掌握MySQL基础知识",
          create_time: "2022-04-11",
          read_num: 1280,
          like_num: 909,
        },
        {
          id: 8,
          pic: require("@/assets/images/lesson08.jpg"),
          title: "零基础前端React实战教程",
          create_time: "2022-04-15",
          read_num: 2000,
          like_num: 1239,
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    activeFn(e) {
      this.activeIndex = e.target.getAttribute("data-index");
    },
  },
  beforeCreate() { }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  beforeMount() { }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="less">
.lesson-wrap {
  width: 1200px;
  margin: 50px 0 0px;
  display: flex;
  flex-wrap: wrap;

  li {
    width: 280px;
    margin: 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .lesson-img {
      width: 280px;
      height: 155px;
      overflow: hidden;

      img {
        width: 280px;
        height: 155px;
        transition: all 0.5s;

        &:hover {
          transform: scale(1.2);
          transition: all 0.5s;
        }
      }
    }

    .info {
      p {
        font-size: 14px;
        line-height: 24px;
      }
    }

    .lesson-icons {
      color: #999;
      font-size: 14px;
      line-height: 40px;

      span {
        margin-right: 10px;
      }
    }

    .mask {
      width: 100%;
      height: 64px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0;
      bottom: -64px;
      transition: all 0.5s;
      cursor: pointer;

      .try_btn {
        width: 70%;
        margin-left: 15%;
        height: 34px;
        margin-top: 15px;
        background: linear-gradient(90deg, #ff0058 0%, #fd4c00 100%),
          linear-gradient(#a5a5a5, #a5a5a5);
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }
    }

    &:hover {
      .mask {
        bottom: 0px;
        transition: all 0.5s;
      }
    }
  }
}
</style>