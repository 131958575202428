<!-- 首页 -->
<template>
  <div class="home">
    <Header :navList="navList" />
    <div id="home_0">
      <HomeSwiper />
      <section class="product" style="padding-bottom: 0; background: #fff">
        <div class="container">
          <div class="section-title" style="padding: 0">
            <h2>2023年5月IT实训报名占座开始！</h2>
            <p>零基础小白7天IT技术入门体验课</p>
          </div>
          <div class="form">
            <input type="text" placeholder="您的姓名" v-model="username" />
            <input type="text" v-model="phone" placeholder="请输入您的手机号码" maxlength="11" />
            <button class="order" @click.prevent="showMaskFn">立即报名</button>
          </div>
        </div>
      </section>
    </div>
    <div id="home_1">
      <section class="product" style="padding-bottom: 0">
        <div class="container">
          <div class="section-title" style="padding: 0">
            <h2>课程体系</h2>
            <p>好谷就业力教研中心权威发布，满足你的学习需求</p>
          </div>
          <div class="tabs">
            <ul>
              <li v-for="(tabsData, index) in tabsDatas" :key="tabsData.id" :class="activeIndex == index ? 'active' : ''"
                @click="changeTabs(index)">
                {{ tabsData.title }}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <component :is="componentName" />
    </div>
    <div id="home_2">
      <HomeLesson />
    </div>
    <div id="home_3">
      <!-- <HomeCase /> -->
      <HomeNumber />
    </div>
    <div id="home_4">
      <HomeAbout />
    </div>

    <div id="home_5">
      <HomeSolution />
    </div>
    <div id="home_6">
      <!-- <HomeNews /> -->
      <HomeFooter />
    </div>
    <div class="mask" v-if="showMask">
      <div class="mask-alert">
        <div class="mask-close" @click="closeMaskFn">
          提示<span @click="closeMaskFn">X</span>
        </div>
        <div class="captcha">
          <input type="text" placeholder="请输入手机验证码" v-model="code" />
        </div>
        <div class="time">{{ num }}后重新获取验证码</div>
        <div class="btn" @click="toClassRoomFn">去选座位</div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { PhoneCode, PhoneLoginCode } from "@/api/home.js";
import Header from "@/components/Header.vue";
import HomeSwiper from "@/components/HomeSwiper";
import HomeLesson from "@/components/HomeLesson";
import HomeProduct from "@/components/HomeProduct";
import HomeProductJava from "@/components/HomeProductJava";
import HomeProductTest from "@/components/HomeProductTest";
import HomeSolution from "@/components/HomeSolution";
import HomeCase from "@/components/HomeCase";
import HomeNumber from "@/components/HomeNumber";
import HomeAbout from "@/components/HomeAbout";
import HomeNews from "@/components/HomeNews";
import HomeFooter from "@/components/HomeFooter";
import bus from "@/utils/bus.js";
// 引入防抖插件  npm i --save lodash.debounce
import debounce from "lodash.debounce";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    Header,
    HomeSwiper,
    HomeProduct,
    HomeProductJava,
    HomeSolution,
    HomeAbout,
    HomeCase,
    HomeNumber,
    HomeNews,
    HomeFooter,
    HomeProductTest,
    HomeLesson,
  },
  data() {
    //这里存放数据
    return {
      username: "",
      phone: "",
      showMask: false,
      activeIndex: 0,
      componentName: "HomeProduct",
      flag: false,
      timer: null,
      code: "",
      num: 0,
      timer2: null,
      navList: [
        {
          id: 1,
          title: "网站首页",
        },
        {
          id: 2,
          title: "课程体系",
        },
        {
          id: 3,
          title: "学习资料",
        },
        {
          id: 4,
          title: "案例展示",
        },
        {
          id: 5,
          title: "关于我们",
        },
        {
          id: 6,
          title: "师资力量",
        },
        {
          id: 7,
          title: "有问有答",
        },
      ],
      tabsDatas: [
        {
          id: 1,
          title: "HTML5大前端",
        },
        {
          id: 2,
          title: "JAVA大数据",
        },
        {
          id: 3,
          title: "软件测试",
        },
      ],
    };
  },
  //方法集合
  methods: {
    async showMaskFn() {
      if (!this.username) {
        this.$message({
          message: "请输入用户名",
          type: "warning",
        });
      } else if (this.username && /^1[35689][0-9]{9}$/.test(this.phone)) {
        this.num = 120;
        this.timer2 = setInterval(() => {
          this.num--;
          if (this.num == 0) {
            this.showMask = false;
            clearInterval(this.timer2);
          }
        }, 1000);

        let result = await PhoneCode({
          username: this.username,
          phone: this.phone,
        });
        // console.log(result);
        if (result.data.status == 200) {
          this.showMask = true;
        } else {
          this.$message({
            message: result.data.msg,
            type: "warning",
          });
        }
      } else if (!/^1[35689][0-9]{9}$/.test(this.phone)) {
        this.$message({
          message: "手机号格式不正确",
          type: "warning",
        });
      }
    },
    closeMaskFn() {
      this.showMask = false;
      this.phone = "";
      this.username = "";
    },
    changeTabs(index) {
      this.activeIndex = index;
      if (index == 0) {
        this.componentName = "HomeProduct";
      } else if (index == 1) {
        this.componentName = "HomeProductJava";
      } else {
        this.componentName = "HomeProductTest";
      }
    },
    async toClassRoomFn() {
      let result = await PhoneLoginCode({
        code: this.code,
      });
      // console.log(result);
      if (result.data.status == 200) {
        this.$router.push("/classroom/333");
      } else {
        this.$message({
          message: result.data.msg,
          type: "warning",
        });
      }
    },
    scrollPage: debounce(function () {
      let detailArr = [];
      detailArr.push(document.getElementById("home_0"));
      detailArr.push(document.getElementById("home_1"));
      detailArr.push(document.getElementById("home_2"));
      detailArr.push(document.getElementById("home_3"));
      detailArr.push(document.getElementById("home_4"));
      detailArr.push(document.getElementById("home_5"));
      detailArr.push(document.getElementById("home_6"));
      // // console.log(detailArr);
      // // console.log(window);
      let { scrollY } = window; //解构赋值  滚动的距离
      // // console.log(scrollY);

      // reduce遍历数组的方法
      // reduce(callback,索引)
      detailArr.reduce((total, currentEle, index) => {
        // total 和
        // currentEle  当前元素
        // index 索引
        if (
          !document.getElementById("home_0") &&
          !document.getElementById("home_1") &&
          !document.getElementById("home_2") &&
          !document.getElementById("home_3") &&
          !document.getElementById("home_4") &&
          !document.getElementById("home_5") &&
          !document.getElementById("home_6")
        ) {
          return;
        }
        // console.log(total);
        if (total) {
          return total;
        }
        if (scrollY + 100 <= currentEle.offsetTop + currentEle.offsetHeight) {
          bus.$emit("sendPageIndex", index);
          total = true;
        }
        return total;
      }, 0);
    }, 500),
  },
  watch: {
    // flag() {
    //   clearTimeout(this.timer);
    //   this.timer = setTimeout(() => {
    //     this.flag = true;
    //   }, 30000);
    // },
  },
  mounted() {
    // this.timer = setTimeout(() => {
    //   this.flag = true;
    // }, 30000);
    bus.$on("sendIndex", (data) => {
      // console.log(data);
      let oSession = document.querySelector("#home_" + data);
      let scrollTop = oSession.offsetTop - window.scrollY - 100;
      window.scrollBy({
        //scrollBy滚动指定的像素值
        top: scrollTop + 1,
        behavior: "smooth", //流畅运动（动画效果）
      });
    });
    window.addEventListener("scroll", this.scrollPage, false);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollPage, false);
  },
};
</script>
<style lang="less">
.tabs {
  width: 100%;
  height: 100px;

  ul {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100px;

    li {
      width: 130px;
      height: 46px;
      background: #efefef;
      line-height: 46px;
      text-align: center;
      margin: 0 10px;
      border-radius: 23px;
      padding: 0 10px;
      cursor: pointer;
    }

    .active {
      color: #fff;
      background-image: linear-gradient(90deg, #ff0058 0%, #fd4c00 100%),
        linear-gradient(#a5a5a5, #a5a5a5);
    }
  }
}

.form {
  display: flex;
  justify-content: center;
  margin: 50px 0;

  input {
    width: 300px;
    margin-right: 20px;
    font-size: 20px;
    text-align: center;
  }

  .order {
    display: block;
    width: 400px;
    height: 60px;
    background-image: linear-gradient(90deg, #ff0058 0%, #fd4c00 100%),
      linear-gradient(#a5a5a5, #a5a5a5);
    font-size: 20px;
    color: #fff;
  }
}

.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  .mask-alert {
    width: 435px;
    height: 200px;
    background: #fff;
    border-radius: 10px;
    position: absolute;
    top: 50%;

    margin-top: -100px;
    margin-left: -150px;
    left: 50%;

    .btn {
      display: block;
      width: 20rem;
      padding: 0.6rem 0;
      background-image: linear-gradient(90deg, #ff0058 0%, #fd4c00 100%),
        linear-gradient(#a5a5a5, #a5a5a5);
      background-blend-mode: normal, normal;
      border-radius: 2rem;
      font-family: Alibaba-PuHuiTi-R;
      font-size: 1.5rem;
      font-weight: normal;
      font-stretch: normal;
      margin: 0rem auto 0;
      color: #ffffff;
      text-align: center;
      -webkit-animation: mscale 1s infinite;
      animation: mscale 1s infinite;
      cursor: pointer;
    }

    .mask-close {
      position: relative;
      height: 44px;
      text-align: center;
      line-height: 44px;
      font-size: 20px;
      font-weight: bold;

      span {
        position: absolute;
        right: 0;
        width: 44px;
        height: 44px;
        text-align: center;
        line-height: 44px;
        border-radius: 50%;
      }
    }

    .captcha {
      display: flex;
      justify-content: center;

      input {
        width: 20rem;
        padding: 0.8rem 0;
        outline: none;
        border: 1px solid #ccc;
        text-align: center;
      }
    }

    .time {
      text-align: center;
      line-height: 40px;
    }
  }
}
</style>
