<!--  -->
<template>
  <section class="number">
    <div class="container">
      <ul class="clearfix">
        <li>
          <b>
            <i id="number-1" data-sum="10">10</i>
            <em>年</em>
          </b>
          <p>互联网前沿技术经验</p>
        </li>
        <li>
          <b>
            <i id="number-2" data-sum="25">25</i>
            <sup>+</sup>
          </b>
          <p>商标、专利、软件著作权</p>
        </li>
        <li>
          <b>
            <i id="number-3" data-sum="360">360</i>
            <em>行</em>
          </b>
          <p>全行业覆盖</p>
        </li>
        <li>
          <b>
            <i id="number-4" data-sum="30">30</i>
            <sup>+</sup>
          </b>
          <p>本、专科院校的认可</p>
        </li>
        <li>
          <b>
            <i id="number-5" data-sum="5000">5000</i>
            <em>学子</em>
          </b>
          <p>大中专院校学生的选择</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
</style>