<!--  -->
<template>
  <div class="home-swiper">
    <div class="slide">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide slide-bg1">
            <div class="ani" swiper-animate-effect="fadeInUp" swiper-animate-duration=".9s" swiper-animate-delay=".1s">
              <img src="@/assets/images/1599510816900308.png" alt="HTML5响应式网站建设" />
            </div>
            <div class="container">
              <div class="ani slide-text" swiper-animate-effect="fadeInDown" swiper-animate-duration=".9s"
                swiper-animate-delay=".1s">
                <h1>HTML5全栈开发</h1>
                <p>零基础丨跨行业也能进大厂赢“薪”职业</p>
                <div class="seemore">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="HTML5全栈开发">
                    立即咨询&nbsp;&nbsp;
                    <i class="fa fa-angle-double-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper-slide slide-bg2">
            <div class="ani" swiper-animate-effect="fadeInUp" swiper-animate-duration=".9s" swiper-animate-delay=".1s">
            </div>
            <div class="container">
              <div class="ani slide-text" swiper-animate-effect="fadeInDown" swiper-animate-duration=".9s"
                swiper-animate-delay=".1s">
                <h1>JAVA大数据</h1>
                <p>Java学到手，就业无需愁</p>
                <div class="seemore">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="JAVA大数据">
                    立即咨询&nbsp;&nbsp;
                    <i class="fa fa-angle-double-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper-slide slide-bg4">
            <div class="ani" swiper-animate-effect="fadeInUp" swiper-animate-duration=".9s" swiper-animate-delay=".1s">
              <img src="@/assets/images/1599510935791745.png" alt="知识产权保护" />
            </div>
            <div class="container">
              <div class="ani slide-text" swiper-animate-effect="fadeInDown" swiper-animate-duration=".9s"
                swiper-animate-delay=".1s">
                <h1>软件测试技术学到手</h1>
                <p>职场任你翱翔，未来由你做主</p>
                <div class="seemore">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="软件测试技术学到手">
                    立即咨询&nbsp;&nbsp;
                    <i class="fa fa-angle-double-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev">
          <i class="fa fa-angle-left fa-5x" aria-hidden="true"></i>
        </div>
        <div class="swiper-button-next">
          <i class="fa fa-angle-right fa-5x" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
// 1. 引入swiper相关

import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      new Swiper(".swiper-container", {
        loop: true,
        speed: 600,
        grabCursor: true,
        parallax: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          init: function () {
            // swiperAnimateCache(this); //隐藏动画元素
            this.emit("slideChangeTransitionEnd"); //在初始化时触发一次slideChangeTransitionEnd事件
          },
          slideChangeTransitionEnd: function () {
            // swiperAnimate(this); //每个slide切换结束时运行当前slide动画
            //this.slides.eq(this.activeIndex).find('.ani').removeClass('ani'); //动画只展示一次
          },
        },
      });
    });
  },
};
</script>
<style lang="less">
.slide {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  min-width: 1180px;
  height: 630px;
  background: #333;
  overflow: hidden;
}

/* slide */
.slide {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  min-width: 1180px;
  height: 630px;
  background: #333;
  overflow: hidden;
}

/* swiper-slide */
.swiper-slide {
  position: relative;
  width: 100%;
  min-width: 1180px;
  height: 630px;
  color: #fff;
  text-align: center;
  overflow: hidden;
}

.slide-bg1,
.slide-bg2,
.slide-bg3,
.slide-bg4,
.slide-bg5 {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.slide-bg1 {
  background: #242a37 url(http://www.hg-zn.com/images/slide_bg1.png) center center no-repeat;
  background-color: #242a37;
}

.slide-bg2 {
  background: #242a37 url(http://www.hg-zn.com/images/slide_bg2.png) center center no-repeat;
  background-color: #242a37;
}

.slide-bg3 {
  background: #242a37 url(http://www.hg-zn.com/images/slide_bg3.png) center center no-repeat;
  background-color: #242a37;
}

.slide-bg4 {
  background: #242a37 url(http://www.hg-zn.com/images/slide_bg4.png) center center no-repeat;
  background-color: #242a37;
}

.slide-bg5 {
  background: #1b294f url(http://www.hg-zn.com/images/slide_bg5.png) center center no-repeat;
  background-color: #1b294f;
}

.swiper-slide .ani img {
  width: 1180px;
  height: 630px;
  overflow: hidden;
}

.swiper-slide .container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1180px;
  height: 400px;
  margin-left: -590px;
  margin-top: -200px;
}

.swiper-slide .slide-text {
  width: 100%;
  padding-top: 90px;
  color: #fff;
  text-align: center;
}

.swiper-slide .slide-text h1 {
  max-height: 150px;
  font-size: 48px;
  font-weight: bold;
  line-height: 72px;
}

.swiper-slide .slide-text p {
  margin: 20px 0 50px;
  max-height: 64px;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 3px;
}

.slide-bg2 .slide-text {
  width: 50%;
  text-align: left;
}

.slide-bg4 .slide-text {
  float: right;
  width: 50%;
  text-align: right;
}

/* slide-nav */
.slide-nav {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100px;
  background: rgba(255, 255, 255, 0.05);
}

.slide-nav ul {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.slide-nav li {
  float: left;
  width: 20%;
  height: 100px;
  color: #fff;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  cursor: pointer;
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.slide-nav li a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.slide-nav li dl {
  display: block;
  float: left;
  height: 60px;
  padding: 20px 15px;
  overflow: hidden;
}

.slide-nav li dl dt {
  height: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  opacity: 0.9;
  filter: alpha(opacity=90);
  overflow: hidden;
}

.slide-nav li dl dt:before {
  content: "";
  position: absolute;
  top: 50px;
  width: 36px;
  height: 2px;
  background: #00a0ea;
  opacity: 0.75;
  filter: alpha(opacity=75);
}

.slide-nav li dl dd {
  height: 24px;
  font-size: 14px;
  line-height: 30px;
  color: #fff;
  opacity: 0.75;
  filter: alpha(opacity=75);
  overflow: hidden;
}

.slide-nav li a:hover {
  background: rgba(255, 255, 255, 0.05);
}

.slide-nav li:nth-child(5) {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
}

/* swiper-pagination */
.swiper-pagination {
  position: absolute;
  z-index: 999;
  bottom: 120px !important;
  left: 0;
  width: 100%;
  text-align: center;
}

.swiper-pagination-bullet {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px !important;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  opacity: 0.3;
  filter: alpha(opacity=30);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.swiper-pagination-bullet-active {
  width: 30px;
  opacity: 0.75;
  filter: alpha(opacity=75);
}

/* swiper-button */
.swiper-button-prev {
  left: 20px;
}

.swiper-button-next {
  right: 20px;
}

.swiper-button-prev,
.swiper-button-next {
  background: none;
}

.swiper-button-prev i,
.swiper-button-next i {
  height: 36px;
  line-height: 36px;
  color: #fff;
  opacity: 0.3;
  filter: alpha(opacity=30);
}

.swiper-button-prev i:hover,
.swiper-button-next i:hover {
  opacity: 0.75;
  filter: alpha(opacity=75);
}
</style>