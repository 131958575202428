<!--  -->
<template>
  <section class="product">
    <div class="container">
      <div class="product-item">
        <ul class="clearfix">
          <li data-index="1" @mouseenter="activeFn" :class="{ 'product-active': activeIndex == 1 }">
            <div class="product-item-normal">
              <div class="product-item-icon">
                <i class="fa fa-laptop fa-2x fa-fw" aria-hidden="true"></i>
              </div>
              <div class="product-item-title">
                <h3>测试基础</h3>
                <p>Linux和数据库满足企业需求</p>
              </div>
              <div class="product-item-desc">
                <p>【 软件测试介绍 】</p>
                <p>【 测试用例 】</p>
                <p>【 缺陷管理 】</p>
                <p>【 项目实战 】</p>
              </div>
              <div class="product-item-price">
                <p>
                  <em>就业薪资</em>
                  <span>3K-5K</span>
                </p>
              </div>
            </div>
            <div class="product-item-active">
              <a href class="product-item-link">
                <div class="product-item-icon">
                  <i class="fa fa-laptop fa-3x fa-fw" aria-hidden="true"></i>
                </div>
                <div class="product-item-title">
                  <h3>测试基础</h3>
                  <p>Linux和数据库满足企业需求</p>
                </div>
                <div class="product-item-desc" style="height: 280px; width: 95%; overflow-y: auto">
                  <p>•了解软件测试行业，并知道软件测试的主流技能</p>
                  <p>•掌握测试点设计方法</p>
                  <p>•掌握缺陷管理能力</p>
                  <p>•掌握针对WEB项目功能测试能力</p>
                  <p>•能够掌握Linux和数据库的常见操作，辅助测试工作</p>
                  <p>•掌握Linux操作系统按照和配置</p>
                  <p>•熟练掌握Linux常用命令</p>
                  <p>•掌握数据库增删改查操作</p>
                  <p>•掌握项目环境搭建及项目中数据库中数据的校验</p>
                </div>
                <div class="product-item-btn">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
                    立即试听
                  </a>
                </div>
              </a>
            </div>
          </li>
          <li data-index="2" @mouseenter="activeFn" :class="{ 'product-active': activeIndex == 2 }">
            <div class="product-item-normal">
              <div class="product-item-icon">
                <i class="fa fa-mobile fa-2x fa-fw" aria-hidden="true"></i>
              </div>
              <div class="product-item-title">
                <h3>功能测试</h3>
                <p>Web项目实战|移动APP测试</p>
              </div>
              <div class="product-item-desc">
                <p>【 掌握项目测试流程 】</p>
                <p>【 分析需求 】</p>
                <p>【 测试用例编写 】</p>
                <p>【 缺陷报告编写 】</p>
              </div>
              <div class="product-item-price">
                <p>
                  <em>就业薪资</em>
                  <span>5K-8K</span>
                </p>
              </div>
            </div>
            <div class="product-item-active">
              <a href class="product-item-link">
                <div class="product-item-icon">
                  <i class="fa fa-mobile fa-3x fa-fw" aria-hidden="true"></i>
                </div>
                <div class="product-item-title">
                  <h3>功能测试</h3>
                  <p>Web项目实战|移动APP测试</p>
                </div>
                <div class="product-item-desc" style="height: 280px; width: 95%; overflow-y: auto">
                  <p>•能够掌握项目测试流程，分析需求，编写合格的测试用例和缺陷报告。</p>
                  <p>•熟练掌握黑盒测试用例设计方法，进行功能测试用例设计</p>
                  <p>•熟练掌握缺陷报告的编写</p>
                  <p>•熟悉测试管理工具禅道和JIRA的使用</p>
                  <p>•掌握项目测试流程</p>
                  <p>•掌握Web项目功能测试分析和用例编写</p>
                  <p>•熟悉测试计划，测试方案，测试报告的核心内容</p>
                  <p>•掌握功能测试与数据库的关系</p>
                  <p>•掌握Fiddler工具的使用</p>
                  <p>•掌握APP测试流程和用例设计方法</p>
                </div>
                <div class="product-item-btn">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
                    立即试听
                  </a>
                </div>
              </a>
            </div>
          </li>
          <li data-index="3" @mouseenter="activeFn" :class="{ 'product-active': activeIndex == 3 }">
            <div class="product-item-normal">
              <div class="product-item-icon">
                <i class="fa fa-bar-chart fa-2x fa-fw" aria-hidden="true"></i>
              </div>
              <div class="product-item-title">
                <h3>Python编程</h3>
                <p>熟练使用Python技术</p>
              </div>
              <div class="product-item-desc">
                <p>【 Python基础 】</p>
                <p>【 数据序列 】</p>
                <p>【 函数/MySql 】</p>
                <p>【 面向对象 】</p>
                <p>【 异常、文件操作 】</p>
                <p>【 Pytest框架 】</p>
              </div>
              <div class="product-item-price">
                <p>
                  <em>就业薪资</em>
                  <span>8K-11K</span>
                </p>
              </div>
            </div>
            <div class="product-item-active">
              <a href class="product-item-link">
                <div class="product-item-icon">
                  <i class="fa fa-bar-chart fa-3x fa-fw" aria-hidden="true"></i>
                </div>
                <div class="product-item-title">
                  <h3>Python编程</h3>
                  <p>熟练使用Python技术</p>
                </div>
                <div class="product-item-desc" style="height: 280px; width: 95%; overflow-y: auto">
                  <p>•熟练使用Python技术，为后续相关自动化测试课程的学习打好基础。</p>
                  <p>•掌握Python基础语法，具备基础的编程能力</p>
                  <p>•建立编程思维以及面向对象程序设计思想</p>
                </div>
                <div class="product-item-btn">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
                    立即试听
                  </a>
                </div>
              </a>
            </div>
          </li>
          <li data-index="4" @mouseenter="activeFn" :class="{ 'product-active': activeIndex == 4 }">
            <div class="product-item-normal">
              <div class="product-item-icon">
                <i class="fa fa-cog fa-2x fa-fw" aria-hidden="true"></i>
              </div>
              <div class="product-item-title">
                <h3>接口测试</h3>
                <p>解决接口自动化测试中常见问题</p>
              </div>
              <div class="product-item-desc">
                <p>【 HTTP协议及接口文档解析 】</p>
                <p>【 Postman基础使用 】</p>
                <p>【 接口用例设计 】</p>
                <p>【 Postman高级用法 】</p>
              </div>
              <div class="product-item-price">
                <p>
                  <em>就业薪资</em>
                  <span>11K-18K</span>
                </p>
              </div>
            </div>
            <div class="product-item-active">
              <a href class="product-item-link">
                <div class="product-item-icon">
                  <i class="fa fa-cog fa-3x fa-fw" aria-hidden="true"></i>
                </div>
                <div class="product-item-title">
                  <h3>接口测试</h3>
                  <p>解决接口自动化测试中常见问题</p>
                </div>
                <div class="product-item-desc" style="height: 280px; width: 95%; overflow-y: auto">
                  <p>•能够封装接口自动化测试框架并实现HTTP或Dubbo接口测试，解决接口自动化测试中常见问题。</p>
                  <p>•掌握接口及接口测试相关概念</p>
                  <p>•掌握使用Postman进行接口测试</p>
                  <p>•熟练掌握使用pymysql数据库实现测试数据准备、校验和清理</p>
                  <p>•掌握requests库使用及脚本封装</p>
                  <p>•掌握如何实现日志收集</p>
                  <p>•掌握如何实现接口自动化测试全量字段校验</p>
                  <p>•掌握接口测试框架的设计和封装</p>
                  <p>•掌握如何测试dubbo接口</p>
                  <p>•掌握在实际的项目中如何灵活运用接口测试的相关技术</p>

                </div>
                <div class="product-item-btn">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
                    立即试听
                  </a>
                </div>
              </a>
            </div>
          </li>
          <li data-index="5" @mouseenter="activeFn" :class="{ 'product-active': activeIndex == 5 }">
            <div class="product-item-normal">
              <div class="product-item-icon">
                <i class="fa fa-registered fa-2x fa-fw" aria-hidden="true"></i>
              </div>
              <div class="product-item-title">
                <h3>性能测试</h3>
                <p>能够对项目实现性能测试</p>
              </div>
              <div class="product-item-desc">
                <p>【 性能测试基础 】</p>
                <p>【 性能测试工具 】</p>
                <p>【 轻商城项目实战 】</p>
                <p>【 Locust框架 】</p>
              </div>
              <div class="product-item-price">
                <p>
                  <em>就业薪资</em>
                  <span>15K-20K</span>
                </p>
              </div>
            </div>
            <div class="product-item-active">
              <a href="#" class="product-item-link">
                <div class="product-item-icon">
                  <i class="fa fa-registered fa-3x fa-fw" aria-hidden="true"></i>
                </div>
                <div class="product-item-title">
                  <h3>性能测试</h3>
                  <p>能够对项目实现性能测试</p>
                </div>
                <div class="product-item-desc" style="height: 280px; width: 95%; overflow-y: auto">
                  <p>•能够对项目实现性能测试，使用JMeter编写性能测试脚本，设计性能测试场景，并进行测试数据监控和分析。</p>
                  <p>•熟练搭建性能测试环境</p>
                  <p>•掌握性能测试基础理论</p>
                  <p>•熟练掌握JMeter工具的使用</p>
                  <p>•掌握使用JMeter进行项目性能测试实战</p>
                  <p>•掌握性能测试监控、性能分析和性能调优的思路</p>
                  <p>•掌握如何使用Locust进行接口性能测试</p>
                </div>
                <div class="product-item-btn">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1">
                    立即试听
                  </a>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      activeIndex: 1,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    activeFn(e) {
      this.activeIndex = e.target.getAttribute("data-index");
    },
  },
  beforeCreate() { }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  beforeMount() { }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
</style>