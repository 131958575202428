<!--  -->
<template>
  <div class="footer">
    <section class="focus">
      <div class="container">
        <div class="focus-item">
          <div class="section-title">
            <h2>感兴趣吗？</h2>
            <p>欢迎联系我们，我们愿意为您解答任何有关网站疑难问题！</p>
          </div>
          <div class="seemore">
            <a href="https://wpa.qq.com/msgrd?v=3&amp;uin=373289962&amp;site=qq&amp;menu=yes" title="在线客服"
              target="_blank">
              立即咨询&nbsp;&nbsp;
              <i class="fa fa-angle-double-right"></i>
            </a>
          </div>
        </div>
      </div>
    </section>

    <footer>
      <!-- footer-safe -->
      <section class="footer-safe">
        <div class="container" style="width:100%">
          <ul class="clearfix">
            <li>
              <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="7x24小时售后支持">
                <i class="fa fa-clock-o fa-2x fa-fw" aria-hidden="true"></i>
                <span>7x24小时售后支持</span>
              </a>
            </li>
            <li>
              <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="跨浏览器兼容">
                <i class="fa fa-internet-explorer fa-2x fa-fw" aria-hidden="true"></i>
                <span>跨浏览器兼容</span>
              </a>
            </li>
            <li>
              <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="符合W3C标准及规范">
                <i class="fa fa-wikipedia-w fa-2x fa-fw" aria-hidden="true"></i>
                <span>符合W3C标准及规范</span>
              </a>
            </li>
            <li>
              <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="100%纯手工编码">
                <i class="fa fa-code fa-2x fa-fw" aria-hidden="true"></i>
                <span>100%纯手工编码</span>
              </a>
            </li>
            <li>
              <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="免费ICP备案服务">
                <i class="fa fa-file-text fa-2x fa-fw" aria-hidden="true"></i>
                <span>免费ICP备案服务</span>
              </a>
            </li>
          </ul>
        </div>
      </section>
      <!-- end footer-safe -->
      <!-- footer-nav -->
      <section class="footer-nav">
        <div class="container" style="width:100%">
          <div class="footer-nav-left">
            <dl>
              <dt>服务项目</dt>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="网站建设">网站建设</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="移动业务">移动业务</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="网络推广">网络推广</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="基础业务">基础业务</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="知识产权">知识产权</a>
              </dd>
            </dl>

            <dl>
              <dt>案例展示</dt>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="企业官网">企业官网</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="品牌营销">品牌营销</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="集团网站">集团网站</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="微官网">微官网</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="小程序">小程序</a>
              </dd>
            </dl>

            <dl>
              <dt>关于我们</dt>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="公司简介">公司简介</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="企业文化">企业文化</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="发展历程">发展历程</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="荣誉资质">荣誉资质</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="合作伙伴">合作伙伴</a>
              </dd>
            </dl>

            <dl>
              <dt>新闻动态</dt>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="域名资讯">域名资讯</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="建站知识">建站知识</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="网站优化">网站优化</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="知产资讯">知产资讯</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="公司新闻">公司新闻</a>
              </dd>
            </dl>

            <dl>
              <dt>联系我们</dt>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="联系方式">联系方式</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="在线留言">在线留言</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="法律申明">法律申明</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="隐私政策">隐私政策</a>
              </dd>

              <dd>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="网站地图">网站地图</a>
              </dd>
            </dl>
          </div>
          <div class="footer-nav-right">
            <div class="footer-contact">
              <div class="contact-text">
                <p>
                  <span>
                    <i class="fa fa-phone fa-1x fa-fw"></i>15837108733
                  </span>
                  ( 7*24h )
                </p>
                <dl>
                  <dd>地 址：郑州高新区新芒果春天1号楼东厅4层</dd>
                  <dd>E-mail：1416323790@qq.com</dd>
                  <dd>
                    网 址：http://www.hg-zn.com
                    <a href></a>
                  </dd>
                </dl>
              </div>
              <div class="qr-code">
                <ul class="clearfix">
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="微博"
                    target="_blank">
                    <li>
                      <div class="qr-code-img">
                        <img src="/static/upload/image/20200908/1599542919593260.jpg" alt="微博" />
                      </div>
                      <i class="fa fa-weibo"></i>
                    </li>
                  </a>
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="微信">
                    <li>
                      <div class="qr-code-img">
                        <img src="/static/upload/image/20200910/1599722333759953.jpg" alt="微信" />
                      </div>
                      <i class="fa fa-weixin"></i>
                    </li>
                  </a>
                  <a href="https://wpa.qq.com/msgrd?v=3&amp;uin=123456789&amp;site=qq&amp;menu=yes" title="QQ"
                    target="_blank">
                    <li>
                      <div class="qr-code-img">
                        <img src="/static/upload/image/20200910/1599722622771892.png" alt="QQ" />
                      </div>
                      <i class="fa fa-qq"></i>
                    </li>
                  </a>
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="抖音">
                    <li>
                      <div class="qr-code-img">
                        <img src="/static/upload/image/20200908/1599542951813767.jpg" alt="抖音" />
                      </div>
                      <i class="fa fa-dot-circle-o"></i>
                    </li>
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- end footer-nav -->
      <section class="container">
        <!-- footer-links -->
        <div class="footer-links">
          <span>友情链接：</span>
          <ul class="clearfix">
            <li>
              <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="好谷就业力"
                target="_blank">好谷就业力</a>
            </li>

            <li>
              <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="好谷就业力"
                target="_blank">好谷就业力</a>
            </li>
          </ul>
        </div>
        <!-- end footer-links -->
        <!-- footer-copyright -->
        <div class="footer-copyright">
          <div class="footer-copyright-txt">
            <span>Copyright © 2022. 郑州好谷就业力 版权所有</span>
            <a href="https://beian.miit.gov.cn/" rel="nofollow" target="_blank">豫ICP备18009515号-1
            </a>
            <span></span>
            <span>Processed in 0.062361 second(s).</span>
          </div>
          <div class="footer-copyright-links">
            <ul class="clearfix">
              <li>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="品牌营销">品牌营销</a>
              </li>

              <li>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="集团网站">集团网站</a>
              </li>

              <li>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title="站点地图">站点地图</a>
              </li>
            </ul>
          </div>
        </div>
        <!-- end footer-copyright -->
      </section>
    </footer>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  beforeCreate() { }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  beforeMount() { }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
