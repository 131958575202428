<!-- 师资力量 -->
<template>
  <section class="solution">
    <div class="container-fluid">
      <div class="section-title">
        <h2>师资力量</h2>
        <p>实战知识剖析讲解，手把手打造你的职场核心竞争力</p>
      </div>

      <div class="solution-item">
        <button class="fa fa-angle-left" tabindex="0" role="button" aria-label="Previous slide"></button>
        <button class="fa fa-angle-right" tabindex="0" role="button" aria-label="Next slide"></button>
        <div class="solution-slide swiper-container-horizontal" style="cursor: grab">
          <ul class="clearfix" style="
              transition-duration: 0ms;
              transform: translate3d(-2124px, 0px, 0px);
            ">
            <li data-swiper-slide-index="1" v-for="teacherList in teacherLists" :key="teacherList.id"
              style="width: 236px">
              <span>
                <hr />
                <h3>{{ teacherList.title }}</h3>
                <p>
                  {{ teacherList.tips }}
                </p>
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title>在线咨询</a>
              </span>
              <img :src="teacherList.pic" alt="许老师" />
            </li>
          </ul>
          <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      teacherLists: [
        {
          id: 1,
          title: "潘老师",
          pic: require("@/assets/images/solution_01.jpg"),
          tips: "毕业于解放军信息工程大学计算机专业。曾担任河南楼市网软件开发部经理、中国移动政企中心自助终端产品项目设计经理。教学理念：不妒才，不自负，能够帮助超越自己的人才，才是人格的升华。成就他人的同时，也在成就自己！让更多的人通过教育改变人生，有梦想一起实现！座右铭：路漫漫其修远兮，吾将上下而求索···",
        },
        {
          id: 2,
          title: "许老师",
          pic: require("@/assets/images/solution_02.jpg"),
          tips: "先后就职于世界500强外企NTT DATA，广电融媒体大河网技术研发部，云计划宇通客车车研所等公司。负责过多项大型项目开发如“东京GAS、Y社全国加盟商管理、 JXE、 NDBS”，大河网媒体综合管理系统，智慧校车云平台，汽车驾控汇系统、智慧城市云平台等",
        },
        {
          id: 3,
          title: "周老师",
          pic: require("@/assets/images/solution_03.jpg"),
          tips: "曾就职上市公司江河瑞通。现好谷就业力学院全栈讲师。十年IT从业经验。精通Java全栈、大数据、C#、Android等技术栈。曾参与南水北调中线水质监测系统、山洪灾害预警系统等。教学风格：风趣幽默，深入浅出传授技能。",
        },
        {
          id: 4,
          title: "李老师",
          pic: require("@/assets/images/solution_04.jpg"),
          tips: "曾担任上海中软科技有限公司高级前端开发工程师现任好谷智能科技有限公司前端讲师同是也是安阳师范学院，河南科技学院，商丘学院特聘讲师讲课风格风趣幽默，以通俗易懂的方式让学生掌握专业知识。",
        },
        {
          id: 5,
          title: "郭老师",
          pic: require("@/assets/images/solution_05.jpg"),
          tips: "从事3年开发经验，多年项目管理老师，现好谷就业力就业老师，在好谷就业力另担任班级管理，助理讲师人力资源管理师，二级心理咨询师",
        },
        {
          id: 6,
          title: "张老师",
          pic: require("@/assets/images/solution_06.jpg"),
          tips: "五年以上的开发经验，曾任职华为技术有限公司高级前端开发工程师，先后参与华为与移动合作手机经分项目，针对内部员工使用，中国移动app部分模块开发，国家电网后台管理系统部分模块开发...",
        },
        {
          id: 7,
          title: "顾老师",
          pic: require("@/assets/images/solution_07.jpg"),
          tips: "宁波中能集团人力资源，人力资源管理师，二级心理咨询师",
        },
        {
          id: 8,
          title: "王老师",
          pic: require("@/assets/images/solution_08.jpg"),
          tips: "拥有5年互联网网站（网上购物商城、行业咨询门户）和移动应用APP开发经验",
        },
        {
          id: 9,
          title: "曹老师",
          pic: require("@/assets/images/solution_09.jpg"),
          tips: "北京海天集团河南区域人力资源主管，二级心理咨询师。陪伴你从校园到职场，用技术开启未来之路，只为成就更好的你们。",
        },
        {
          id: 10,
          title: "刘老师",
          pic: require("@/assets/images/solution_10.jpg"),
          tips: "5年以上开发经验，曾负责开发中铁建智慧社区的后台管理系统以及APP内的部分功能模块开发，曾就职于华为技术有限公司前端开发工程师，参与开发中国移动人工座席所使用的交互中心项目，独立开发信阳本地生活小程序惠生活。",
        },
        {
          id: 11,
          title: "李老师",
          pic: require("@/assets/images/solution_11.jpg"),
          tips: "10年多年从业经验，具有丰富的APP与大型网站开发经验，精通Java主流技术，大数据技术，人工智能。曾任北京宽连十方公司首席技术官（CTO）、华易科技有限公司技术总监等，曾带领团队研发冲浪快讯（中国移动江苏省分公司项目），冲浪浏览器（研发属于中国人首个独创的浏览器内核），电子图书集成化管理项目等。具有丰富的设计、开发、项目管理、团队管理经验",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      new Swiper(".solution-slide", {
        loop: true,
        wrapperClass: "solution-slide ul",
        slideClass: "solution-slide ul li",
        slidesPerView: 5,
        speed: 600,
        grabCursor: true,
        parallax: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
          paginationClickable: true,
        },
        navigation: {
          prevEl: ".solution .fa-angle-left",
          nextEl: ".solution .fa-angle-right",
        },
      });
    });
  },
};
</script>
<style>
</style>