<!--  -->
<template>
  <section class="about">
    <div class="about-bg"></div>
    <div class="container">
      <div class="section-title">
        <h2>关于我们</h2>
        <p>
          专注与技术与能力并重的IT技能培养,支持新一代青年人用技术开启职业之路
        </p>
      </div>

      <div class="about-desc">
        <p></p>
        <p style="text-indent: 2em">
          好谷是河南领先的信息化智能技术提供商，河南省科技型企业，公司专注于交通、医疗、电力、职业教育等领域，拥有Solver技术咨询服务管理系统、企业在线服务管理平台、交互式研发项目管理系统、大数据公共服务平台等10多项软硬件研发专利，并通过了国家知识产权管理体系认证，致力于为客户提供信息系统技术开发、大数据的技术支持与产品服务、大学生校外实习实训与创业基地建设等。
        </p>
        <div class="seemore">
          <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title>
            查看更多&nbsp;&nbsp;
            <i class="fa fa-angle-double-right"></i>
          </a>
        </div>
      </div>
      <video controls="controls" height="100%" width="100%">
        <source src="http://www.hg-zn.com/mp4/jianjie.mp4" type="video/mp4" />
      </video>
    </div>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  beforeCreate() { }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  beforeMount() { }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
</style>