<!--  -->
<template>
  <section class="cases">
    <div class="container">
      <div class="section-title">
        <h2>案例展示</h2>
        <p>用心做好每一个网站，案例是我们最好的名片</p>
      </div>

      <div class="cases-item clearfix">
        <div class="hd">
          <!-- <ul>
            <li
              :class="activeIndex == index ? 'on' : ''"
              v-for="(caseTitle, index) in caseTitles"
              :key="caseTitle.cid"
              @mouseover="changeCaseTitle(index, caseTitle.cid)"
            >
              {{ caseTitle.title }}
            </li>
          </ul> -->
        </div>
        <div class="bd">
          <ul>
            <li v-for="list in lists" :key="list.id">
              <div class="cases-images">
                <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" :title="list.title">
                  <img :src="'https://www.hg-zn.com/' + list.image_input" width="340" height="auto" :alt="list.title" />
                </a>
              </div>
              <div class="cases-text">
                <h3>
                  <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" :title="list.title">{{
                    list.title
                  }}</a>
                </h3>
                <p>{{ list.synopsis }}···</p>
              </div>
              <div class="cases-date">
                <div class="cases-time">
                  <i class="fa fa-clock-o"></i> {{ list.create_time }}
                </div>
                <div class="" style="color: #999">
                  <span>
                    <i class="fa fa-eye" aria-hidden="true"></i>{{ list.r_num }}
                  </span>
                  <span>
                    <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>{{ list.z_num }}
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="more">
        <a href="https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1" title>
          查看更多&nbsp;&nbsp;
          <i class="fa fa-angle-double-right"></i>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { HomeCaseApi } from "@/api/home.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      activeIndex: 0,
      cid: 1,
      caseTitles: [
        {
          cid: "1",
          title: "企业官网",
        },
        {
          cid: "2",
          title: "品牌营销",
        },
        {
          cid: "3",
          title: "集团网站",
        },
        {
          cid: "4",
          title: "微官网",
        },
        {
          cid: "5",
          title: "小程序",
        },
      ],
      lists: [],
    };
  },
  //方法集合
  methods: {
    changeCaseTitle(index, cid) {
      this.activeIndex = index;
      this.cid = cid;
      this.getHomeCaseDatas();
    },
    getHomeCaseDatas() {
      HomeCaseApi({
        cid: this.cid,
      }).then((res) => {
        // console.log(res.data.data);
        this.lists = res.data.data;
      });
    },
  },
  beforeCreate() { }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  beforeMount() { }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getHomeCaseDatas();
  },
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style></style>