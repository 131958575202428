<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { isMobile } from "@/utils/tools.js";
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {
    if (isMobile()) {
      this.$router.push("/mobile");
    } else {
      // this.$router.push("/");
    }
  },
};
</script>

<style >
@import "./assets/css/normalize.css";
@import "./assets/css/swiper.min.css";
@import "./assets/css/animate.min.css";
@import "./assets/css/main.css";
@import "./assets/css/index.css";
@import "./assets/css/font-awesome.min.css";
</style>
