var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"product"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"product-item"},[_c('ul',{staticClass:"clearfix"},[_c('li',{class:{ 'product-active': _vm.activeIndex == 1 },attrs:{"data-index":"1"},on:{"mouseenter":_vm.activeFn}},[_vm._m(0),_vm._m(1)]),_c('li',{class:{ 'product-active': _vm.activeIndex == 2 },attrs:{"data-index":"2"},on:{"mouseenter":_vm.activeFn}},[_vm._m(2),_vm._m(3)]),_c('li',{class:{ 'product-active': _vm.activeIndex == 3 },attrs:{"data-index":"3"},on:{"mouseenter":_vm.activeFn}},[_vm._m(4),_vm._m(5)]),_c('li',{class:{ 'product-active': _vm.activeIndex == 4 },attrs:{"data-index":"4"},on:{"mouseenter":_vm.activeFn}},[_vm._m(6),_vm._m(7)]),_c('li',{class:{ 'product-active': _vm.activeIndex == 5 },attrs:{"data-index":"5"},on:{"mouseenter":_vm.activeFn}},[_vm._m(8),_vm._m(9)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-normal"},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-laptop fa-2x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("测试基础")]),_c('p',[_vm._v("Linux和数据库满足企业需求")])]),_c('div',{staticClass:"product-item-desc"},[_c('p',[_vm._v("【 软件测试介绍 】")]),_c('p',[_vm._v("【 测试用例 】")]),_c('p',[_vm._v("【 缺陷管理 】")]),_c('p',[_vm._v("【 项目实战 】")])]),_c('div',{staticClass:"product-item-price"},[_c('p',[_c('em',[_vm._v("就业薪资")]),_c('span',[_vm._v("3K-5K")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-active"},[_c('a',{staticClass:"product-item-link",attrs:{"href":""}},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-laptop fa-3x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("测试基础")]),_c('p',[_vm._v("Linux和数据库满足企业需求")])]),_c('div',{staticClass:"product-item-desc",staticStyle:{"height":"280px","width":"95%","overflow-y":"auto"}},[_c('p',[_vm._v("•了解软件测试行业，并知道软件测试的主流技能")]),_c('p',[_vm._v("•掌握测试点设计方法")]),_c('p',[_vm._v("•掌握缺陷管理能力")]),_c('p',[_vm._v("•掌握针对WEB项目功能测试能力")]),_c('p',[_vm._v("•能够掌握Linux和数据库的常见操作，辅助测试工作")]),_c('p',[_vm._v("•掌握Linux操作系统按照和配置")]),_c('p',[_vm._v("•熟练掌握Linux常用命令")]),_c('p',[_vm._v("•掌握数据库增删改查操作")]),_c('p',[_vm._v("•掌握项目环境搭建及项目中数据库中数据的校验")])]),_c('div',{staticClass:"product-item-btn"},[_c('a',{attrs:{"href":"https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1"}},[_vm._v(" 立即试听 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-normal"},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-mobile fa-2x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("功能测试")]),_c('p',[_vm._v("Web项目实战|移动APP测试")])]),_c('div',{staticClass:"product-item-desc"},[_c('p',[_vm._v("【 掌握项目测试流程 】")]),_c('p',[_vm._v("【 分析需求 】")]),_c('p',[_vm._v("【 测试用例编写 】")]),_c('p',[_vm._v("【 缺陷报告编写 】")])]),_c('div',{staticClass:"product-item-price"},[_c('p',[_c('em',[_vm._v("就业薪资")]),_c('span',[_vm._v("5K-8K")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-active"},[_c('a',{staticClass:"product-item-link",attrs:{"href":""}},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-mobile fa-3x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("功能测试")]),_c('p',[_vm._v("Web项目实战|移动APP测试")])]),_c('div',{staticClass:"product-item-desc",staticStyle:{"height":"280px","width":"95%","overflow-y":"auto"}},[_c('p',[_vm._v("•能够掌握项目测试流程，分析需求，编写合格的测试用例和缺陷报告。")]),_c('p',[_vm._v("•熟练掌握黑盒测试用例设计方法，进行功能测试用例设计")]),_c('p',[_vm._v("•熟练掌握缺陷报告的编写")]),_c('p',[_vm._v("•熟悉测试管理工具禅道和JIRA的使用")]),_c('p',[_vm._v("•掌握项目测试流程")]),_c('p',[_vm._v("•掌握Web项目功能测试分析和用例编写")]),_c('p',[_vm._v("•熟悉测试计划，测试方案，测试报告的核心内容")]),_c('p',[_vm._v("•掌握功能测试与数据库的关系")]),_c('p',[_vm._v("•掌握Fiddler工具的使用")]),_c('p',[_vm._v("•掌握APP测试流程和用例设计方法")])]),_c('div',{staticClass:"product-item-btn"},[_c('a',{attrs:{"href":"https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1"}},[_vm._v(" 立即试听 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-normal"},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-bar-chart fa-2x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("Python编程")]),_c('p',[_vm._v("熟练使用Python技术")])]),_c('div',{staticClass:"product-item-desc"},[_c('p',[_vm._v("【 Python基础 】")]),_c('p',[_vm._v("【 数据序列 】")]),_c('p',[_vm._v("【 函数/MySql 】")]),_c('p',[_vm._v("【 面向对象 】")]),_c('p',[_vm._v("【 异常、文件操作 】")]),_c('p',[_vm._v("【 Pytest框架 】")])]),_c('div',{staticClass:"product-item-price"},[_c('p',[_c('em',[_vm._v("就业薪资")]),_c('span',[_vm._v("8K-11K")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-active"},[_c('a',{staticClass:"product-item-link",attrs:{"href":""}},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-bar-chart fa-3x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("Python编程")]),_c('p',[_vm._v("熟练使用Python技术")])]),_c('div',{staticClass:"product-item-desc",staticStyle:{"height":"280px","width":"95%","overflow-y":"auto"}},[_c('p',[_vm._v("•熟练使用Python技术，为后续相关自动化测试课程的学习打好基础。")]),_c('p',[_vm._v("•掌握Python基础语法，具备基础的编程能力")]),_c('p',[_vm._v("•建立编程思维以及面向对象程序设计思想")])]),_c('div',{staticClass:"product-item-btn"},[_c('a',{attrs:{"href":"https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1"}},[_vm._v(" 立即试听 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-normal"},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-cog fa-2x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("接口测试")]),_c('p',[_vm._v("解决接口自动化测试中常见问题")])]),_c('div',{staticClass:"product-item-desc"},[_c('p',[_vm._v("【 HTTP协议及接口文档解析 】")]),_c('p',[_vm._v("【 Postman基础使用 】")]),_c('p',[_vm._v("【 接口用例设计 】")]),_c('p',[_vm._v("【 Postman高级用法 】")])]),_c('div',{staticClass:"product-item-price"},[_c('p',[_c('em',[_vm._v("就业薪资")]),_c('span',[_vm._v("11K-18K")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-active"},[_c('a',{staticClass:"product-item-link",attrs:{"href":""}},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-cog fa-3x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("接口测试")]),_c('p',[_vm._v("解决接口自动化测试中常见问题")])]),_c('div',{staticClass:"product-item-desc",staticStyle:{"height":"280px","width":"95%","overflow-y":"auto"}},[_c('p',[_vm._v("•能够封装接口自动化测试框架并实现HTTP或Dubbo接口测试，解决接口自动化测试中常见问题。")]),_c('p',[_vm._v("•掌握接口及接口测试相关概念")]),_c('p',[_vm._v("•掌握使用Postman进行接口测试")]),_c('p',[_vm._v("•熟练掌握使用pymysql数据库实现测试数据准备、校验和清理")]),_c('p',[_vm._v("•掌握requests库使用及脚本封装")]),_c('p',[_vm._v("•掌握如何实现日志收集")]),_c('p',[_vm._v("•掌握如何实现接口自动化测试全量字段校验")]),_c('p',[_vm._v("•掌握接口测试框架的设计和封装")]),_c('p',[_vm._v("•掌握如何测试dubbo接口")]),_c('p',[_vm._v("•掌握在实际的项目中如何灵活运用接口测试的相关技术")])]),_c('div',{staticClass:"product-item-btn"},[_c('a',{attrs:{"href":"https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1"}},[_vm._v(" 立即试听 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-normal"},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-registered fa-2x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("性能测试")]),_c('p',[_vm._v("能够对项目实现性能测试")])]),_c('div',{staticClass:"product-item-desc"},[_c('p',[_vm._v("【 性能测试基础 】")]),_c('p',[_vm._v("【 性能测试工具 】")]),_c('p',[_vm._v("【 轻商城项目实战 】")]),_c('p',[_vm._v("【 Locust框架 】")])]),_c('div',{staticClass:"product-item-price"},[_c('p',[_c('em',[_vm._v("就业薪资")]),_c('span',[_vm._v("15K-20K")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-item-active"},[_c('a',{staticClass:"product-item-link",attrs:{"href":"#"}},[_c('div',{staticClass:"product-item-icon"},[_c('i',{staticClass:"fa fa-registered fa-3x fa-fw",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"product-item-title"},[_c('h3',[_vm._v("性能测试")]),_c('p',[_vm._v("能够对项目实现性能测试")])]),_c('div',{staticClass:"product-item-desc",staticStyle:{"height":"280px","width":"95%","overflow-y":"auto"}},[_c('p',[_vm._v("•能够对项目实现性能测试，使用JMeter编写性能测试脚本，设计性能测试场景，并进行测试数据监控和分析。")]),_c('p',[_vm._v("•熟练搭建性能测试环境")]),_c('p',[_vm._v("•掌握性能测试基础理论")]),_c('p',[_vm._v("•熟练掌握JMeter工具的使用")]),_c('p',[_vm._v("•掌握使用JMeter进行项目性能测试实战")]),_c('p',[_vm._v("•掌握性能测试监控、性能分析和性能调优的思路")]),_c('p',[_vm._v("•掌握如何使用Locust进行接口性能测试")])]),_c('div',{staticClass:"product-item-btn"},[_c('a',{attrs:{"href":"https://tb.53kf.com/code/client/423e2e57b63230cfcf173b4d662ff8e91/1"}},[_vm._v(" 立即试听 ")])])])])
}]

export { render, staticRenderFns }