<template>
  <header>
    <section class="header-top">
      <div class="container">
        <div class="header-logo">
          <h1>
            <a href title="好谷就业力">
              <img src="@/assets/images/logo.png" alt="好谷就业力" />
            </a>
          </h1>
          <h2>让河南学子创造中国互联网未来！</h2>
        </div>
        <div class="header-search">
          <form action="" method="get">
            <input
              type="text"
              name="keyword"
              autocomplete="off"
              placeholder="搜索从这里开始..."
            />
            <input type="hidden" name="field" value="title" />
            <input type="hidden" name="field" value="content" />
            <input type="hidden" name="scode" value="19" />
            <button type="submit">
              <i class="fa fa-search"></i>
            </button>
          </form>
        </div>
      </div>
    </section>
    <section class="header-bottom">
      <div class="container">
        <div class="header-nav">
          <ul id="nav">
            <li
              class="nLi"
              v-for="(nav, index) in navList"
              :key="nav.id"
              @click="changeNav(index)"
            >
              <h3>
                <a
                  href="javascript:;"
                  :class="activeIndex == index ? 'active' : ''"
                  :title="nav.title"
                  >{{ nav.title }}</a
                >
              </h3>
            </li>
          </ul>
        </div>
        <div class="header-mobile">
          <p>
            【 服务热线 】
            <span>
              <i class="fa fa-volume-control-phone fa-fw" aria-hidden="true"></i
              >15837108733
            </span>
          </p>
        </div>
      </div>
    </section>
  </header>
</template>

<script>
import bus from "@/utils/bus.js";
export default {
  name: "HelloWorld",
  props: {
    navList: Array,
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  methods: {
    changeNav(index) {
      this.activeIndex = index;
      bus.$emit("sendIndex", index);
    },
  },
  mounted() {
    bus.$on("sendPageIndex", (data) => {
      this.activeIndex = data;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  min-width: 1180px;
  height: 100px;
  background: 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.header:hover {
  background: #242a37;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.header-top {
  height: 49px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-logo,
.header-logo h1,
.header-logo h2 {
  float: left;
  height: 49px;
}

.header-logo h2 {
  margin-left: 10px;
  font-size: 14px;
  line-height: 49px;
  color: #fff;
}

.header-logo img {
  display: block;
  width: auto;
  height: 49px;
}
</style>
